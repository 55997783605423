import {
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Nav,
  Tab, Tabs,
  Alert
} from 'react-bootstrap';
import {FaCircle, FaLongArrowAltRight, FaRegCalendarAlt} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/UserTrackingSystem.module.css';
import checkBoxClasses from './style/TrackingDetailsAfterEnroll.module.css'
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';
import {
  enrollFree,
  addCart,
  assignAndStartTrack,
} from '../Tracking/Trackings.service';

import { getAllPackageLevels, getmemberForAssign , getMyPackageLevels, getRanking, switchCurrentUserPackage } from './Initiatives.service';

import {
  getTrackingsDetailsBeforeEnroll,
} from './Initiatives.service';

import {isUserLoggedIn, getUserId} from '../../auth/utiles';
import {useNavigate} from 'react-router-dom';
import './style/VideoPlayer.css';
import CancelButton from '../../UI/cancel-button/CancelButton';
import CustomCheckbox from '../Tracking/components/CustomCheckbox';
import TrackingCard from './components/TrackingCard';
import { getmemberForAssignPackages, getmemberForAssignPackagesLevels} from '../Trackings/Trackings.service';
import ShareIcon from '../../components/share-icon/ShareIcon';
import moment from 'moment';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { IoCartOutline } from "react-icons/io5";
import { colors } from '../../constants/colors';
import CircleProgress from '../../UI/circle-progress/CircleProgress';


const IntiativeDetails = () => {

  const [showAssignUsersLevels, setShowAssignUsersLevels] = useState(false);

  const {t} = useTranslation();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [discover, setDiscover] = useState('0');

  const[userPackageEnrollmentId , setUserPackageEnrollmentId ] = useState(null)

  const[rankingList , setRankingList ] = useState([])

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  

  useEffect(() => {
    const tabFromQueryParam = queryParams.get('discover');
    if (tabFromQueryParam && tabFromQueryParam == '1' ) {
      setDiscover('1')
    }else{
      getMembersLevels(id)
    }
    
  }, []);

 


  

  const [url, setUrl] = useState('https://championsacademy.page.link');

  const createDynamicLink = async () => {
    
   
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://championsacademy.page.link',
        link: 
        `${getDynamicLinkDomain()}/?type=InitiativePackage&model_id=${id}&invitation_id=${ trackingDetails?.user_package_enrollment_id ?? id}${`&invitee_id=${localStorage.getItem("id") }`}`,
        androidInfo: {
          androidPackageName: 'com.championsacademy',
        },
        iosInfo: {
          iosBundleId: 'org.Champions.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBlnJ9M_bXXKck16qjoInYIRo0xFp5pPPU`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  const navigate = useNavigate();

  const isLoggedIn = isUserLoggedIn();

  const userId = getUserId() ?? '';

  const cartData = useAppSelector(state => state.cart.data);

  const {id} = useParams();

  const dispatch = useAppDispatch();

  const [tracking, setTracking] = useState(null);

  const [enrolled, setEnrolled] = useState(false);

  const [show, setShow] = useState(false);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [showTrakingTerms, setShowTrakingTerms] = useState(false);

  const[acceptTerms , setAcceptTerms ] = useState(false);

  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const [trackings, setTrackings] = useState([]);

  const [trackingDetails, setTrackingDetails] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);

  const [levelshasNotEqualOne, setLevelshasNotEqualOne] = useState(false);

  

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    document.getElementById('video').play();
  };


  const current_user_id = queryParams.get('current_user_id') || null ;


  const getData = async () => {
      dispatch(setLoading(true));
      let result = ''
      if( queryParams.get('discover') == '1' ){
        result = await getAllPackageLevels(id);
      }else{
        //result = await getMyPackageLevels(id , current_user_id);
        result = await getMyPackageLevels(id);
      }

      let record = result.response
      if( record && record.not_available){
        navigate('/not-available')
      }else{
        setTrackings(result.response.data.package_levels.data);
        setLevelshasNotEqualOne(result.response.data.package_levels.data.some(obj => obj.enrolled_in_level !== 1))
        setTrackingDetails(result.response.data.package_details)
        setUserPackageEnrollmentId(result.response.data.package_details.user_package_enrollment_id)
        setMemberLevel(result.response.data.package_details.selected_enrolled_user_id)

        const url = new URL(window.location.href);
        url.searchParams.set('current_user_id', result.response.data.package_details.selected_enrolled_user_id);
        navigate(url.pathname + url.search, { replace: true });
      }
      
      dispatch(setLoading(false));

  };

  

  const existInCart = () => {
    return cartData.find(i => i.name == trackingDetails.name);
  };

  const enroll = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();

      destinationArray.map((i, index) => data.append(`users_ids[${index}]`, i.id));
     
        data.append('module_name', 'TrackingPackage');
        data.append('module_id', String(id));
     

      if(  ( queryParams.get('type') || queryParams.get('modal_type')  )&& queryParams.get('model_id') ){
        let type = queryParams.get('type')
        let modal_type = queryParams.get('modal_type')
        let invitationId = queryParams.get('invitation_id')
        

        data.append('invitation_id', invitationId);
        if( queryParams.get('invitation_id') == trackingDetails?.id ){
          data.append('invitation_user_id', queryParams.get('invitee_id') );
        }
        data.append('invitation_type', type || modal_type );
        
      }

      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
        dispatch(setLoading(false));
        setShow(false);

        navigate('/user/initiatives')
       
      }
    } catch (e) {
      console.log({e});
    }
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      try {
        let data = new FormData();
        for( let i=0 ; i < destinationArray.length ; i++ ){
         
            data.append('model_id', String(id));
            data.append('model_name', 'TrackingPackage');

          
          data.append(`items[${i}][model_id]`, String(id));
          data.append(`items[${i}][model_name]`, 'TrackingPackage');
          data.append(`items[${i}][user_id]`, destinationArray[i].id);

        }

        if(  queryParams.get('type') && queryParams.get('model_id') ){
          let type = queryParams.get('type')
          let invitationId = queryParams.get('invitation_id')
          
  
          data.append('invitation_id', invitationId);
          if( queryParams.get('invitation_id') == trackingDetails?.id ){
            data.append('invitation_user_id', queryParams.get('invitee_id') );
          }
          data.append('invitation_type', type );
          
        }
        
        let result = await addCart(data);
        console.log('resultresultresultresultENROL', result);
        dispatch(setLoading(false));
        setShowAssignUsers(false)
      } catch (e) {
        console.log({e});
      }
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    if( trackingDetails ){
      createDynamicLink()
    }
  }, [trackingDetails]);

  const getRankList = async () => {
    let result = await getRanking(id, 1);
    console.log('result.response.data.data', result.response.data.data);
    if (result.response) {
      setRankingList(result.response?.data.data);
    }
  };

  useEffect(() => {
    getData();
    getMembers();
    if( discover != '1' ){ getRankList() }

  }, []);

  const getMembers = async () => {
    let result = await getmemberForAssignPackages(id);

   

    
    if (result.response?.data.length > 0) {

      console.log('assign users', result.response.data);

      let data = result.response.data

      //const newData = data.map(item => ({
        //...item,
        //id: item.id.toString(), // Convert id to string
      //}));
  
      let newData = data.map(item => ({ ...item, checked: false }));

      setSourceArray(newData);
    }
  };

  const getMembersLevels = async () => {
    let result = await getmemberForAssignPackagesLevels(id);
    if (result.response?.data.length > 0) {

      console.log('assign users for package levels', result.response.data);

      let data = result.response.data

      setMembersLevels(data);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log('value, checked', value, checked )
    if (checked) {
        // If checkbox is checked, add the value to the selectedValues array
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
        // If checkbox is unchecked, remove the value from the selectedValues array
        setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item !== value)
        );
    }

    console.log('selectedValues' , selectedValues )
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )

    };

  const Assign = async () => {
    if (member) {
      console.log('data');
      console.log('id ', id);
      console.log(
        'tracking.user_tracking_enrollment_id',
        tracking.user_tracking_enrollment_id,
      );
      console.log('member ', member);
      let data = new FormData();
      data.append('tracking_id', id);
      if (trackingDetails?.user_tracking_enrollment_id) {
        data.append(
          'user_tracking_enrollment_id',
          tracking.user_tracking_enrollment_id,
        );
      }
      data.append(`members[0]`, member);
      let result = await assignAndStartTrack(data);
      console.log('result', result);
      setShowAssignUsers(false);
    }
  };

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      Limited - Only you or a family member can start this regimen and you can
      not re-take it after you have finished it.
    </Popover>
  );

  const [sourceArray, setSourceArray] = useState([]);

  const [destinationArray, setDestinationArray] = useState([]);

  const handleCheckboxChangeItems = (id) => {
    // Find the item in the source array
    const selectedItem = sourceArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = true
      // Update checked status in the source array
      const updatedSourceArray = sourceArray.map(item =>
        item.id === id ? { ...item, checked: !item.checked } : item
      );

      // Update the state with the modified source array
      const newSourceData = sourceArray.filter(item => item.id !== id);
      setSourceArray(newSourceData);

      setDestinationArray(prevArray => [...prevArray, selectedItem]);
    }
  };

  const handleDestinationCheckboxChangeItems = (id) => {
    // Find the item in the source array
    const selectedItem = destinationArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = false

      setSourceArray(prevArray => [...prevArray, selectedItem]);

      // Update the state with the modified source array
      const newDestinationData = destinationArray.filter(item => item.id !== id);
      

      setDestinationArray(newDestinationData);
    }
  };

  const [membersLevels, setMembersLevels] = useState([]);

  const [memberLevel, setMemberLevel] = useState(null);


  const getAssignedUserVal = (e) => {
    console.log(e.target.value);
    setMemberLevel(e.target.value);
  };

  const redirectWithNewId = async () => {
    
    dispatch(setLoading(true));
    try {
      let data = new FormData();

      
      data.append('current_user_id', String(memberLevel));
      data.append('id', String(id));

      let result = await switchCurrentUserPackage(data);
      if (result.response) {
        /*
        dispatch(setLoading(false));
        setShowAssignUsersLevels(false);
        getData();
        getMembersLevels(id)
        */
      
        window.location.replace(
          `/user/initiatives/${id}/package/levels?discover=0&current_user_id=${memberLevel}&tab=${activeTab}`
        );
        
      }
    } catch (e) {
      console.log({e});
    }
  };

  
  const [activeTab, setActiveTab] = useState(queryParams.get('tab') || 'levels');

  const handleSelect = (selectedTab) => {

      const paramName = 'tab';
      const paramValue = selectedTab
      if (queryParams.has(paramName)) {
        queryParams.set(paramName, paramValue);
      } else {
        queryParams.append(paramName, paramValue);
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.location.href = newUrl;
  };

  return (
    <>
      { trackingDetails ? 
      <Container className="mt-5 mb-5">

      {/*tabs*/}
      <div className='containers-tab main-tabs'>

        {/*switch*/}
        { discover == 0 ? 
        <div className='d-flex align-items-center cursor-pointer mb-2'>

          {/*show assign users*/}
          <div
          onClick={() => {

            setShowAssignUsersLevels(true)

          } }
              style={{
                padding: '5px 15px 5px 15px',
                border: '1px solid #E1E1E1',
                borderRadius: '6px',
                margin: '5px',
              }}
              className="d-flex justify-content-between align-items-center mb-3 mt-3">
              <div>
                <img
                  src={trackingDetails?.selected_user_avatar ?? '/icons/manHolder.png'}
                  style={{
                    width: '30px',
                    height: '30px',
                    margin: '0 10px',
                    borderRadius: '50%',
                  }}
                />
                <span>{trackingDetails?.selected_user_name ?? '' }</span>
              </div>
              <div>
                <img src='/down_small_fill.svg' alt="img" />
              </div>
              
            </div>
          {/*show assign users*/}

        </div>
        : '' }
        {/*switch*/}  

        <Tabs
          
          id="uncontrolled-tab-example"
          className="custom-web-tabs mb-4"
          activeKey={activeTab} onSelect={handleSelect}
          >
          <Tab eventKey="about" title="About">
          <Row>
              {/*about*/}
              <Container className="mb-5">
                <Row>

                  

                  
                  <Col md={6} className={classes.TrackingDetails}>
                    <div>

                      <h1 className="main-title mb-4">{trackingDetails?.name}</h1>

                      

                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          
                        
                          <div style={{fontSize: '1.2rem'}}>
                            {trackingDetails?.price == 0 && 'Free'}
                            {trackingDetails?.price > 1 && (
                              <span
                                style={{fontWeight: '600'}}
                                className="text-dark mr-1 ml-1">
                                <img
                                  src={'/icons/coin.png'}
                                  alt="course-img"
                                  style={{
                                    marginRight: '10px',

                                    verticalAlign: 'sub',
                                  }}
                                />
                                <span> {trackingDetails?.price} {trackingDetails?.user_currency}</span>
                              </span>
                            )}
                          </div>

                        

                        

                        </div>
                      </div>

                      

                      <div>
                        <h4>About System</h4>
                        <ReactQuill theme="bubble" value={trackingDetails?.long_description} readOnly={true}  />
                      </div>
                        

                      

                      


                      
                    </div>
                  </Col>

                  <Col md={6} className={classes.TrackingMedia}>
                    <Card className={classes.TrackVideo}>
                      <div className={classes.TrackImgContainer}>
                        <div className={classes.CardImgContainer}>
                          <img
                              className="card-img-top"
                              alt="courseImg"
                              src={trackingDetails?.image}
                            />
                        </div>
                      </div>
                    </Card>
                  </Col>

                  
                </Row>
              </Container>
              {/*about*/}
            
           
    
              
            </Row>
          </Tab>
          <Tab eventKey="levels" title="Levels">
          <Row>
            {trackings.length ?    
              <Col className='d-flex justify-content-between align-items-center share-dropdown my-5'>
                <h4>Initiative Levels</h4>
                <div className='d-flex align-items-center'>

                
                {
                  moment(new Date(), 'YYYY-MM-DD').isSameOrBefore(moment(trackingDetails?.last_end_date, 'YYYY-MM-DD'),'day') && trackingDetails?.type == 'tracking'
                  ?
                  <ShareIcon url={url} hasInvite={true} />
                  : 
                trackingDetails?.type == 'initiative'
                  ?
                  <ShareIcon url={url} hasInvite={true} />
                  :
                  ''
                }

                
                </div>
                
                
                
              </Col>
              : '' }
              </Row>

      {
          trackings.length
          ?
         
          <Row>
          { trackings.length && trackings.map((tracking) => {
              return (
                <Col lg={4}>
                  <TrackingCard
                    tracking={tracking}
                    isPackage={false}
                    is_discover={discover == 1 ? 1 : 0 }
                    packageId={id}
                    current_user_id ={ current_user_id }
                    packagePrice={trackingDetails?.price}
                    hasPricedLevel={trackingDetails?.has_priced_level}
                    cumulative_score_calculated={trackingDetails?.cumulative_score_calculated}
                    showError={ () => setShowErrorMsg(true)}
                  />
                </Col>
              );
            })}
            </Row> 

          :
          <h2 className='text-center mt-5'>No Records Found</h2>
          }

             
          </Tab>
          { discover != '1' ? 
          <Tab eventKey="rank" title="Ranking">
            { trackingDetails?.cumulative_score_calculated ? 
            <Row>
              <Col md="12" className="mt-3 mb-0">
                <h3 className='points-title'>Subscirbers Namber  <span className='points-val'>({rankingList.length}) </span></h3>
              </Col>   
              {rankingList.map((item, i) => {
                  return (
                  <Col md="12" className='mt-4'>   
                      <Card style={{ padding: '0rem', overflow: 'hidden' }}>
                          <div className='d-flex justify-content-between align-items-center'  >
                              <div className='d-flex align-items-center justify-content-center'>

                                  

                                    { item.rank == 1 ? 
                                    <div className={`rank-details first-rank`}>
                                      <img src='/gold.svg' />
                                      <div className='rank'>{item.rank}</div>
                                    </div>
                                    : '' }

                                    { item.rank == 2 ? 
                                    <div className={`rank-details second-rank`}>
                                      <img src='/silver.svg' />
                                      <div className='rank'>{item.rank}</div>
                                    </div>
                                    : '' }

                                    { item.rank == 3 ? 
                                    <div className={`rank-details third-rank`}>
                                      <img src='/bronze.svg' />
                                      <div className='rank'>{item.rank}</div>
                                    </div>
                                    : '' }

                                    { item.rank > 3 ?    
                                    <div className={`rank-details normal-rank`}>
                                      
                                      <div className='rank'>{item.rank}</div>
                                    </div>
                                    : '' }

                                  
                                  
                                  <span style={{ position: 'relative' , margin: '10px 20px', }}>
                                      <img src='/icons/man.png' style={{ borderRadius: '50%' , width: '60px' }} />
                                  </span>
                                  <span
                                  className='ranking-name'
                                  style={{
                                      fontSize: '20px',
                                      fontWeight: '500',
                                      color: item?.me ? colors.orange : '#000'
                                  }}  
                                  >{item?.name} {item?.me ? '(You)' : '' } </span>
                              </div>
                              
                              <div className='mx-2' style={{ scale: '.8' }}>
                                  <CircleProgress
                                  progressColor='#e37e08'
                                  background='#fff4e8'
                                  titleColor='#e37e08'
                                  value={ item.score ? ((item.score == 100.00) ? 100 : parseFloat(item.score).toFixed(1)) : 0 }/>
                              </div>
                              
                          </div>

                      </Card>
                  </Col>

              );
              })}
            </Row>    
            :
            <Alert className='mt-3' variant='danger'>
            Evaluation will be available after challenge completion and activities review
            </Alert>
            }
          </Tab>
          : '' }
        </Tabs>

        {trackingDetails ?    
              <Col md={12} className={`${classes.TrackingDetails} p-0`}>
                <div>
                
                  <div>
                    {
                    (trackingDetails?.price && trackingDetails?.selected_enrolled_user_id == null) || ( trackingDetails?.price && discover == 1)
                    ||
                    (levelshasNotEqualOne && trackingDetails?.price )
                      ? (
                      <MainButton
                        text={ existInCart() ? 'View Cart' : 

                          <div className='d-flex justify-content-center align-items-center'><IoCartOutline size="20" />  <p className='mb-0' style={{ margin: '0 5px' }} >Book The Full Package</p> | { trackingDetails?.price }  { trackingDetails?.user_currency } </div>
                         }   
                        onClick={() => { 
                          if( trackingDetails?.cumulative_score_calculated ){
                            setShowErrorMsg(true)
                          }else{
                            if( existInCart() ){
                              navigate('/cart')
                            }else{
                              setShowAssignUsers(true)
                            }
                        }
                        }}
                      />
                    ) : (
                      ''
                    )}
    
                    {(trackingDetails?.price == 0 && trackingDetails?.selected_enrolled_user_id == null) ||  ( trackingDetails?.price == 0 && discover == 1) ||
                    (levelshasNotEqualOne && trackingDetails?.price == 0 ) ? (
                      <div  onClick={() => setShowAssignUsers(true)}>
                      <MainButton text="Enroll Now" />
                    </div>
                    ) : (
                      ''
                    )}
                    
                  </div>
                </div>
              </Col>
              : '' }
      </div>
      {/*tabs*/}

      
        
      </Container>
      : '' }
      

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        
        <Modal.Body>
          

         
         
        
          {
            sourceArray.length
            ?
            <h5 className="secondry-color mt-4 mb-3">
              Choose one or more family member
            </h5>
            :
            
            <h5 className="secondry-color mt-4 mb-3">
            No member family found as all member are assigned to this initiative
          </h5>
          }  


          
          {/*new array*/}
          {
              sourceArray.map((item,i) => (
              <div className="d-flex justify-content-between align-items-center mb-3" key={item.id}>
                  <div>
                      <img 
                      src={item.avatar ?? '/icons/manHolder.png' }
                      alt='image'
                      style={{
                          width: '60px',
                          borderRadius: '50%',
                          height: '60px',
                          margin:'0 20px 0 0',
                      }}
                      />
                      <span>{item.username}</span>
                  </div>

                  <div>


                  <div>
                      
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChangeItems(item.id)}
                        
                      />
                  </div>

                  

                  </div>    
              </div>
              ))
          }



     

      {
        destinationArray.length
        ?
        <h5 className="secondry-color mt-4 mb-3">
        Selected family members
      </h5>
        :
        ''
      }
     
      {destinationArray.map(item => (
       <div className="d-flex justify-content-between align-items-center mb-3" key={item.id}>
       <div>
           <img 
           src={item.avatar ?? '/icons/manHolder.png' }
           alt='image'
           style={{
               width: '60px',
               borderRadius: '50%',
               height: '60px',
               margin:'0 20px 0 0',
           }}
           />
           <span>{item.username}</span>
       </div>

       <div>


       <div>
           
           <input
             type="checkbox"
             checked={item.checked}
             onChange={() => handleDestinationCheckboxChangeItems(item.id)}
             id={item.id}
             name={'user-data'}
             
           />
       </div>

       

       </div>    
   </div>
      ))}
        {/*new array*/}
        <hr />
        {
          trackingDetails?.price && destinationArray.length
          ?
          <div className='d-flex justify-content-between' style={{ 'fontWeight' : 'bold' }}>
            <p>Total</p>
            <p>{ trackingDetails?.price * destinationArray.length } { trackingDetails?.user_currency }</p>
          </div>
          :
          ''
        }
        
        </Modal.Body>

        <Modal.Footer>
          <Row className='w-100'>
          <Col xs={{span: 12}} md={{span: 12}}>
            <label className={classes.CheckBoxContainer}>
              <div>
                I accept the{' '}
                <span
                  onClick={ () => {
                    setShowAssignUsers(false)
                    setShowTrakingTerms(true)
                  } }
                  className={classes.TermsAndConditions}>
                  Terms and conditions
                </span>
              </div>
              <input type="checkbox" checked={acceptTerms} id="accept" name="accept" onChange={(event) => {
                
                setAcceptTerms(!acceptTerms)
              } } />
              <span className={classes.Checkmark}></span>
            </label>
          </Col>
          </Row>
          <div className="w-100">
            <MainButton
              text={ trackingDetails?.price ? 'Add To Cart' :  'Enroll Now' }
              onClick={() => {
                if( trackingDetails?.price ){
                  addToCart()
                }else{
                  enroll()
                }
              }}
              disabled={destinationArray.length == 0 || acceptTerms != true }
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsersLevels}
        onHide={() => setShowAssignUsersLevels(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className={checkBoxClasses.AuctionType}>
            <ul className="relative mb-3 w-full flex flex-wrap items-stretch justify-center p-0 hideScroll">
              {membersLevels.map((e, i) => (
                <li className="text-center" key={i}>
                  <input
                    type="radio"
                    name="test"
                    id={`name-${i}`}
                    value={e.id}
                    onChange={getAssignedUserVal}
                    disabled={e?.id == trackingDetails?.selected_enrolled_user_id}
                    checked={e?.id == memberLevel}
                  />

                  <label htmlFor={`name-${i}`}>
                    <div>
                      <img
                        src={e.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <h6>{e.username}</h6>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={t('Switch Now')}
              disabled={memberLevel == trackingDetails?.selected_enrolled_user_id}
              onClick={() => {
                redirectWithNewId();
              }}
            />
          </div>

          <div onClick={() => setShowAssignUsersLevels(false)} className="w-100">
            <CancelButton text={t('Cancel')} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*assign user pop up*/}
      <Modal
        show={showTrakingTerms}
        onHide={() => {
          setShowAssignUsers(true) 
          setShowTrakingTerms(false)
        } }
        centered
        size='xl'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          
            <ReactQuill theme="bubble" value={trackingDetails?.terms_and_conditions} readOnly={true}  />
          
        </Modal.Body>

        <Modal.Footer>
          

          
        </Modal.Footer>
      </Modal>

      {/*Show Error Msg*/}
      <Modal show={showErrorMsg} onHide={() => setShowErrorMsg(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <h5 className="text-center mt-4 mb-3">
            This program is no longer available 
        </h5>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => setShowErrorMsg(false)}>
            <MainButton text={'Confirm'} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IntiativeDetails;
